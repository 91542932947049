import { useAuth } from "../../contexts/auth.context";

const UserDetails = () => {
    const {currentUser} = useAuth();

    return (
        <div className='modal fade' id='user-details' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-400px'>
            <div className='modal-content'>
            <div className='modal-header pt-0'>
                <h4 className='fw-semibold text-primary'>{currentUser?.user_name}</h4>
            </div>

            <div className='modal-body scroll-y'>
                <div className="user-details-container">
                    <div className="user-profile">
                        <h5>Username: <span className="fw-normal">{currentUser?.user_name}</span></h5>
                        <h5>User Email: <span className="fw-normal">{currentUser?.user_email}</span></h5>
                        <h5>Primary Org Email: <span className="fw-normal">{currentUser?.organization_primaryEmail}</span></h5>
                        <h5>Phone: <span className="fw-normal">{currentUser?.user_phone}</span></h5>
                        <h5>Organization: <span className="fw-normal">{currentUser?.agency_agencyName}</span></h5>
                    </div>
                </div>
                <div className='text-center d-flex justify-content-center'>
                    <button type='submit' id='user-details-submit' data-bs-dismiss="modal" className='btn btn-lg btn-primary w-100 ms-5'>
                        <span className='indicator-label'>Ok</span>
                    </button>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default UserDetails;
