import clsx from 'clsx';
import * as React from 'react';
import { CheckboxModel } from '../../models/checkbox.model';
import InputField from '../input-field/input-field';

type Props = {
    model: CheckboxModel,
    onChangeHandler: (index:number) => void
}

const CheckboxItem = ({ model, onChangeHandler }:Props) => {
    const {label, isChecked, customOption, position} = model;
    const [checked, setChecked] = React.useState(isChecked);

    const onChange = () => {
        setChecked(!checked);
        onChangeHandler(position);
    };

    return (
      <label className='checkbox-item'>
        {label}
        <input type="checkbox" checked={checked} onChange={() => onChange()} />
        <span className="checkmark"><i className='stepper-check fas fa-check'></i></span>

        {customOption && 
            <InputField 
                label="" 
                labelClass='' 
                className={clsx('form-control form-control-lg form-control-solid my-2 py-0 mw-450px')}
                name='Other'
                rows='5'
                as = "textarea"
                style={{height: 'unset'}}
            />
        }
      </label>
    );
  };

export default CheckboxItem;